<template>
    <b-container>
        <b-skeleton-wrapper :loading="loading">
            <template #loading>
                <b-card>
                    <b-skeleton width="85%"></b-skeleton>
                    <b-skeleton width="55%"></b-skeleton>
                    <b-skeleton width="70%"></b-skeleton>
                </b-card>
            </template>
            <b-jumbotron>
                <h4>
                    <router-link :to="{ name: 'classes' }" class="mr-4">
                        <b-icon-arrow-left-circle scale="1.5"></b-icon-arrow-left-circle>
                    </router-link>
                    <span v-if="id">Edit Class</span>
                    <span v-else>Add Class</span>
                </h4>
                <b-form class="mt-4">
                    <b-form-group
                        label="Name"
                    >
                        <b-input v-model="classModel.class_name" />
                    </b-form-group>
                    <b-form-group
                        label="Teacher Names"
                    >
                        <b-input v-model="classModel.teacher_name" />
                    </b-form-group>
                    <!--<b-form-group label="Tagged input using dropdown" label-for="tags-with-dropdown">
                    <b-form-tags id="tags-with-dropdown" v-model="value" no-outer-focus class="mb-2">
                        <template v-slot="{ tags, disabled, addTag, removeTag }">
                        <ul v-if="tags.length > 0" class="list-inline d-inline-block mb-2">
                            <li v-for="tag in tags" :key="tag" class="list-inline-item">
                            <b-form-tag
                                @remove="removeTag(tag)"
                                :title="tag"
                                :disabled="disabled"
                                variant="info"
                            >{{ tag }}</b-form-tag>
                            </li>
                        </ul>

                        <b-dropdown size="sm" variant="outline-secondary" block menu-class="w-100">
                            <template #button-content>
                                <b-icon icon="tag-fill"></b-icon> Choose tags
                            </template>
                            <b-dropdown-form @submit.stop.prevent="() => {}">
                            <b-form-group
                                label="Search tags"
                                label-for="tag-search-input"
                                label-cols-md="auto"
                                class="mb-0"
                                label-size="sm"
                                :description="searchDesc"
                                :disabled="disabled"
                            >
                                <b-form-input
                                v-model="search"
                                id="tag-search-input"
                                type="search"
                                size="sm"
                                autocomplete="off"
                                ></b-form-input>
                            </b-form-group>
                            </b-dropdown-form>
                            <b-dropdown-divider></b-dropdown-divider>
                            <b-dropdown-item-button
                            v-for="option in availableOptions"
                            :key="option"
                            @click="onOptionClick({ option, addTag })"
                            >
                            {{ option }}
                            </b-dropdown-item-button>
                            <b-dropdown-text v-if="availableOptions.length === 0">
                            There are no tags available to select
                            </b-dropdown-text>
                        </b-dropdown>
                        </template>
                    </b-form-tags>
                    </b-form-group>-->
                    <b-form-group
                        label="Description"
                    >
                        <b-textarea v-model="classModel.description"></b-textarea>
                    </b-form-group>
                    <b-form-group
                        label="Location"
                    >
                        <b-input v-model="classModel.class_location"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Start Time"
                    >
                        <b-input type="time" v-model="classModel.start_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="End Time"
                    >
                        <b-input type="time" v-model="classModel.end_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Min Students"
                    >
                        <b-input type="number" v-model.number="classModel.min_students"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Max Students"
                    >
                        <b-input type="number" v-model.number="classModel.max_students"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Min"
                    >
                        <b-input type="number" v-model.number="classModel.age_min"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Max"
                    >
                        <b-input type="number" v-model.number="classModel.age_max"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Cost"
                    >
                        <b-input-group>
                            <b-input-group-prepend>
                                <b-input-group-text>$</b-input-group-text>
                            </b-input-group-prepend>
                            <b-input type="number" v-model.number="classModel.cost"></b-input>
                            <b-input-group-append>
                                <b-input-group-text>.00</b-input-group-text>
                            </b-input-group-append>
                        </b-input-group>
                    </b-form-group>
                    <!--<select-relationship
                        title="Form"
                        v-model="classModel.form_id"
                        :get-src="(id) => `/api/forms/${id}/fetchLess`"
                        list-src="/api/forms/admin/list"
                        list-key="forms"
                        item-key="form"
                        display-key="title"
                        get-key="id"
                        variant="primary"
                        class="m-3"
                    ></select-relationship>-->
                    <!--
                    <b-form-group
                        label="Start Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.start_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Stop Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.stop_time"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="First Class Date"
                    >
                        <b-input type="datetime-local" v-model="semesterModel.first_class_date"></b-input>
                    </b-form-group>
                    <b-form-group
                        label="Age Intermediate Period"
                    >
                        <b-input v-model.number="semesterModel.age_intermediate_period"></b-input>
                    </b-form-group>
                    
                    <b-form-group
                        label="Toggle Settings"
                    >
                        <b-checkbox v-model="signup.select_person" switch>Selectable Person Sign-up</b-checkbox>
                        
                        <b-checkbox v-model="signup.add_other" switch> Add Other Person</b-checkbox>
                        <b-checkbox v-model="signup.allow_changes" switch> Allow Sign-up Changes</b-checkbox>
                        <b-checkbox v-model="signup.locked" switch>Lock Sign-up</b-checkbox>
                        <b-checkbox v-model="signup.pinned" switch>Pin to "Sign-ups" Page</b-checkbox>
                    </b-form-group>
                    -->
                    <div class="text-center mt-4">
                        <b-button
                            :disabled="saving"
                            variant="primary"
                            size="lg"
                            @click="saveClass()"
                            pill
                        >
                            <b-spinner v-if="saving" variant="light"></b-spinner>
                            <font-awesome-icon v-else icon="save"></font-awesome-icon>
                            Save Class
                        </b-button>
                    </div>
                </b-form>
            </b-jumbotron>
        </b-skeleton-wrapper>
    </b-container>
</template>
<script>
import { mapState } from 'vuex';
/*import SelectRelationship from '@/components/SelectRelationship.vue'
    components: { SelectRelationship }, */

export default {
    props: ["id"],
    data: function () {
        return {
            loading    : false,
            saving     : false,
            classModel : {}
        };
    },
    mounted() {
        if (this.id) this.loadClass();
    },
    methods: {
        loadClass() {
            this.loading = true;
            this.$api.get(`/api/admin/fetch-class/${this.id}`).then((response) => {
                this.classModel = response.data.class;
                this.loading       = false;
            });
        },
        saveClass() {
            this.saving = true;
            this.classModel.semester_id = this.semester.id;
            this.$api.post(`/api/admin/save-class`, this.classModel).then(({ data }) => {
                if (data.class_)
                {
                    this.classModel = data.class_;
                    if (!this.id)
                        this.$router.push({ name: "edit_class", params: { id: data.class_.id }});
                }
                this.$bvToast.toast(data.message, {
                    title           : "Notification",
                    autoHideDelay   : 5000,
                    appendToast     : true
                });
                this.saving = false;
            });
        }
    },
    computed: mapState(["semester"])
}
</script>